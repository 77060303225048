import React from "react";

import {
  Heading,
  FormControl,
  FormLabel,
  Input,
  Text,
  Textarea,
  Button,
} from "@chakra-ui/react";

import Footer from "../components/Footer";
import Framed from "../components/Framed";
import Header from "../components/Header";
import { SpecificHeaders } from "../components/SocialHeaders";
import { useHeaderImage } from "../components/images/dustedCookiesHeader";

export default function ContactPage() {
  return (
    <>
      <Header imageHook={useHeaderImage}>
        <SpecificHeaders title="Contact Us" path="/contact" />
        <Heading as="p">Contact Us</Heading>
      </Header>
      <Framed
        as="main"
        id="main"
        px={[0, null, null, "200px", null, "276px"]}
        pt={["1rem", null, null, "90px"]}
        textStyle="form"
      >
        <Text mb={{ base: 0, lg: "2em" }}>
          We like hearing from like minded groups and individuals, so if you
          would like to get in touch then drop us a line here.
        </Text>
        <form
          name="contact-v4"
          method="POST"
          action="/contact-success/"
          data-netlify="true"
          data-netlify-honeypot="bob"
        >
          <input type="hidden" name="form-name" value="contact-v4" />
          <FormControl display="none">
            <FormLabel>Your Favourite Flower</FormLabel>
            <Input type="text" name="bob" />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Name</FormLabel>
            <Input type="text" name="name" />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Email</FormLabel>
            <Input type="email" name="email" />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Message</FormLabel>
            <Textarea name="msg" />
          </FormControl>

          <Button
            type="submit"
            mt="2rem"
            mb={["3rem", null, null, "140px"]}
            bg="lightBlue.700"
            _hover={{ bg: "lightBlue.800" }}
            _active={{ bg: "lightBlue.900" }}
            color="brand.light"
          >
            Submit
          </Button>
        </form>
      </Framed>
      <Footer />
    </>
  );
}
