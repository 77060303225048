import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";

export function useHeaderImage() {
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(
          relativePath: { eq: "headers/sugar-dusted-cookies-5749589.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  );
  const image = getImage(placeholderImage);

  return {
    image,
    credit: "RitaE on Pixabay",
    url: "https://pixabay.com/photos/cookies-christmas-cookies-plate-5749589/",
  };
}
